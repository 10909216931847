// eslint-disable-next-line no-restricted-imports
import {
  isEmpty,
  isPlainObject,
  assign,
  findIndex,
  extend,
  isNil,
  isObject,
  last,
  isFunction,
  includes,
  has,
  find,
  isString,
  isArray,
  each,
  cloneDeep,
  set,
  merge,
} from 'lodash-es';

function filterBuilder(options, newFilters) {
  const filters = isEmpty(newFilters)
    ? {
        and: [],
        or: [],
        not: [],
      }
    : newFilters;

  const makeFilter = pushQuery.bind(Object.assign({ isInFilterContext: true }, options), filters);

  function addMinimumShouldMatch(str, override) {
    filters.minimum_should_match = str;
    filters.minimum_should_match_override = override;
  }

  return {
    /**
     * Add a filter clause to the query body.
     *
     * @param  {string}        type    Filter type.
     * @param  {string|Object} field   Field to filter or complete filter
     *                                 clause.
     * @param  {string|Object} value   Filter term or inner clause.
     * @param  {Object}        options (optional) Additional options for the
     *                                 filter clause.
     * @param  {Function}      [nest]  (optional) A function used to define
     *                                 sub-filters as children. This _must_ be
     *                                 the last argument.
     *
     * @return {bodybuilder} Builder.
     *
     * @example
     * bodybuilder()
     *   .filter('term', 'user', 'kimchy')
     *   .build()
     */
    filter(...args) {
      makeFilter('and', ...args);
      return this;
    },

    /**
     * Alias for `filter`.
     *
     * @return {bodybuilder} Builder.
     */
    andFilter(...args) {
      return this.filter(...args);
    },

    /**
     * Alias for `filter`.
     *
     * @return {bodybuilder} Builder.
     */
    addFilter(...args) {
      return this.filter(...args);
    },

    /**
     * Add a "should" filter to the query body.
     *
     * Same arguments as `filter`.
     *
     * @return {bodybuilder} Builder.
     */
    orFilter(...args) {
      makeFilter('or', ...args);
      return this;
    },

    /**
     * Add a "must_not" filter to the query body.
     *
     * Same arguments as `filter`.
     *
     * @return {bodybuilder} Builder.
     */
    notFilter(...args) {
      makeFilter('not', ...args);
      return this;
    },

    /**
     * Set the `minimum_should_match` property on a bool filter with more than
     * one `should` clause.
     *
     * @param  {any} param  minimum_should_match parameter. For possible values
     *                      see https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-minimum-should-match.html
     * @param {boolean} override  allows minimum_should_match to be overridden, ignoring internal constraints
     * @return {bodybuilder} Builder.
     */
    filterMinimumShouldMatch(param, override) {
      addMinimumShouldMatch(param, !!override);
      return this;
    },

    getFilter() {
      return this.hasFilter() ? toBool(filters) : {};
    },

    hasFilter() {
      return !!(filters.and.length || filters.or.length || filters.not.length);
    },

    getRawFilter() {
      return filters;
    },
  };
}

/**
 * Compound sort function into the list of sorts
 *
 * @private
 *
 * @param  {Array} current Array of Elasticsearch sorts.
 * @param  {String} field Field to sort.
 * @param  {String|Object} value A valid direction ('asc', 'desc') or object with sort options
 * @returns {Array} Array of Elasticsearch sorts.
 */
function sortMerge(current, field, value) {
  let payload;

  if (isPlainObject(value)) {
    payload = { [field]: assign({}, value) };
  } else {
    payload = { [field]: { order: value } };
  }

  const idx = findIndex(current, (o) => {
    return o[field] !== undefined;
  });

  if (isPlainObject(value) || idx === -1) {
    current.push(payload);
  } else {
    extend(current[idx], payload);
  }

  return current;
}

/**
 * Generic builder for query, filter, or aggregation clauses.
 *
 * @private
 *
 * @param  {string|Object} field Field name or complete clause.
 * @param  {string|Object} value Field value or inner clause.
 * @param  {Object}        opts  Additional key-value pairs.
 *
 * @return {Object} Clause
 */
function buildClause(field, value, opts) {
  const hasField = !isNil(field);
  const hasValue = !isNil(value);
  let mainClause = {};

  if (hasValue) {
    mainClause = { [field]: value };
  } else if (isObject(field)) {
    mainClause = field;
  } else if (hasField) {
    mainClause = { field };
  }

  return Object.assign({}, mainClause, opts);
}

function toBool(filters) {
  const unwrapped = {
    must: unwrap(filters.and),
    should: unwrap(filters.or),
    must_not: unwrap(filters.not),
    minimum_should_match: filters.minimum_should_match,
  };

  if (filters.and.length === 1 && !unwrapped.should && !unwrapped.must_not) {
    return unwrapped.must;
  }

  const cleaned = {};

  if (unwrapped.must) {
    cleaned.must = unwrapped.must;
  }
  if (unwrapped.should) {
    cleaned.should = filters.or;
  }
  if (unwrapped.must_not) {
    cleaned.must_not = filters.not;
  }
  if ((unwrapped.minimum_should_match && filters.or.length > 1) || filters.minimum_should_match_override) {
    cleaned.minimum_should_match = unwrapped.minimum_should_match;
  }

  return {
    bool: cleaned,
  };
}

function unwrap(arr) {
  return arr.length > 1 ? arr : last(arr);
}

function pushQuery(existing, boolKey, type, ...args) {
  const nested = {};
  if (isFunction(last(args))) {
    const nestedCallback = args.pop();
    const nestedResult = nestedCallback(
      Object.assign(
        {},
        filterBuilder({ isInFilterContext: this.isInFilterContext }),
        this.isInFilterContext ? {} : queryBuilder({ isInFilterContext: this.isInFilterContext }),
      ),
    );
    if (!this.isInFilterContext && nestedResult.hasQuery()) {
      nested.query = nestedResult.getQuery();
    }
    if (nestedResult.hasFilter()) {
      nested.filter = nestedResult.getFilter();
    }
  }

  if (includes(['bool', 'constant_score'], type) && this.isInFilterContext && has(nested, 'filter.bool')) {
    // nesting filters: We've introduced an unnecessary `filter.bool`
    existing[boolKey].push({ [type]: Object.assign(buildClause(...args), nested.filter.bool) });
  } else if (type === 'bool' && has(nested, 'query.bool')) {
    existing[boolKey].push({ [type]: Object.assign(buildClause(...args), nested.query.bool) });
  } else {
    // Usual case
    existing[boolKey].push({ [type]: Object.assign(buildClause(...args), nested) });
  }
}

function queryBuilder(options, newQuery) {
  const query = isEmpty(newQuery)
    ? {
        and: [],
        or: [],
        not: [],
      }
    : newQuery;

  const makeQuery = pushQuery.bind(options || {}, query);

  function addMinimumShouldMatch(str, override = false) {
    query.minimum_should_match = str;
    query.minimum_should_match_override = override;
  }

  return {
    /**
     * Add a query clause to the query body.
     *
     * @param  {string}        type    Query type.
     * @param  {string|Object} field   Field to query or complete query clause.
     * @param  {string|Object} value   Query term or inner clause.
     * @param  {Object}        options (optional) Additional options for the
     *                                 query clause.
     * @param  {Function}      [nest]  (optional) A function used to define
     *                                 sub-filters as children. This _must_ be
     *                                 the last argument.
     *
     * @return {bodybuilder} Builder.
     *
     * @example
     * bodybuilder()
     *   .query('match_all')
     *   .build()
     *
     * bodybuilder()
     *   .query('match_all', { boost: 1.2 })
     *   .build()
     *
     * bodybuilder()
     *   .query('match', 'message', 'this is a test')
     *   .build()
     *
     * bodybuilder()
     *   .query('terms', 'user', ['kimchy', 'elastic'])
     *   .build()
     *
     * bodybuilder()
     *   .query('nested', { path: 'obj1', score_mode: 'avg' }, (q) => {
     *     return q
     *       .query('match', 'obj1.name', 'blue')
     *       .query('range', 'obj1.count', {gt: 5})
     *   })
     *   .build()
     */
    query(...args) {
      makeQuery('and', ...args);
      return this;
    },

    /**
     * Alias for `query`.
     *
     * @return {bodybuilder} Builder.
     */
    andQuery(...args) {
      return this.query(...args);
    },

    /**
     * Alias for `query`.
     *
     * @return {bodybuilder} Builder.
     */
    addQuery(...args) {
      return this.query(...args);
    },

    /**
     * Add a "should" query to the query body.
     *
     * Same arguments as `query`.
     *
     * @return {bodybuilder} Builder.
     */
    orQuery(...args) {
      makeQuery('or', ...args);
      return this;
    },

    /**
     * Add a "must_not" query to the query body.
     *
     * Same arguments as `query`.
     *
     * @return {bodybuilder} Builder.
     */
    notQuery(...args) {
      makeQuery('not', ...args);
      return this;
    },

    /**
     * Set the `minimum_should_match` property on a bool query with more than
     * one `should` clause.
     *
     * @param  {any} param  minimum_should_match parameter. For possible values
     *                      see https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-minimum-should-match.html
     * @param {boolean} override  allows minimum_should_match to be overridden, ignoring internal constraints
     * @return {bodybuilder} Builder.
     */
    queryMinimumShouldMatch(param, override) {
      addMinimumShouldMatch(param, !!override);
      return this;
    },

    getQuery() {
      return this.hasQuery() ? toBool(query) : {};
    },

    hasQuery() {
      return !!(query.and.length || query.or.length || query.not.length);
    },

    getRawQuery() {
      return query;
    },
  };
}

function aggregationBuilder(newAggregations) {
  const aggregations = isEmpty(newAggregations) ? {} : newAggregations;

  function makeAggregation(type, field, ...args) {
    const aggName = find(args, isString) || `agg_${type}_${field}`;
    const opts = find(args, isPlainObject);
    const nested = find(args, isFunction);
    const nestedClause = {};
    const metadata = {};

    if (isFunction(nested)) {
      const nestedResult = nested(Object.assign({}, aggregationBuilder(), filterBuilder()));
      if (nestedResult.hasFilter()) {
        nestedClause.filter = nestedResult.getFilter();
      }
      if (nestedResult.hasAggregations()) {
        nestedClause.aggs = nestedResult.getAggregations();
      }
    }

    if (opts && opts._meta) {
      Object.assign(metadata, { meta: opts._meta });
      if (opts && '_meta' in opts) {
        delete opts._meta;
      }
    }

    const innerClause = Object.assign(
      {},
      {
        [type]: buildClause(field, null, opts),
      },
      metadata,
      nestedClause,
    );

    Object.assign(aggregations, {
      [aggName]: innerClause,
    });
  }

  return {
    /**
     * Add an aggregation clause to the query body.
     *
     * @param  {string|Object} type      Name of the aggregation type, such as
     *                                   `'sum'` or `'terms'`.
     * @param  {string}        field     Name of the field to aggregate over.
     * @param  {Object}        [options] (optional) Additional options to
     *                                   include in the aggregation.
     *                         [options._meta] associate a piece of metadata with individual aggregations
     * @param  {string}        [name]    (optional) A custom name for the
     *                                   aggregation, defaults to
     *                                   `agg_<type>_<field>`.
     * @param  {Function}      [nest]    (optional) A function used to define
     *                                   sub-aggregations as children. This
     *                                   _must_ be the last argument.
     *
     * @return {bodybuilder} Builder.
     *
     * @example
     * bodybuilder()
     *   .aggregation('max', 'price')
     *   .build()
     *
     * bodybuilder()
     *   .aggregation('percentiles', 'load_time', {
     *     percents: [95, 99, 99.9]
     *   })
     *   .build()
     *
     * bodybuilder()
     *   .aggregation('date_range', 'date', {
     *     format: 'MM-yyy',
     *     ranges: [{ to: 'now-10M/M' }, { from: 'now-10M/M' }]
     *   })
     *   .build()
     *
     * bodybuilder()
     *   .aggregation('diversified_sampler', 'user.id', { shard_size: 200 }, (a) => {
     *     return a.aggregation('significant_terms', 'text', 'keywords')
     *   })
     *   .build()
     *
     * bodybuilder()
     *   .aggregation('terms', 'title', {
     *      _meta: { color: 'blue' }
     *    }, 'titles')
     *   .build()
     *
     */
    aggregation(...args) {
      makeAggregation(...args);
      return this;
    },

    /**
     * Alias for `aggregation`.
     *
     * @return {bodybuilder} Builder.
     */
    agg(...args) {
      return this.aggregation(...args);
    },

    getAggregations() {
      return aggregations;
    },

    hasAggregations() {
      return !isEmpty(aggregations);
    },

    getRawAggregations() {
      return aggregations;
    },
  };
}

/**
 * **http://bodybuilder.js.org**
 *
 * **https://github.com/danpaz/bodybuilder**
 *
 * Bodybuilder is a small library that makes elasticsearch queries easier to
 * write, read, and maintain 💪. The whole public api is documented here, but
 * how about a simple example to get started:
 *
 * ```
 * bodybuilder()
 *   .query('match', 'message', 'this is a test')
 *   .build()
 *
 * // results in:
 * {
 *   query: {
 *     match: {
 *       message: 'this is a test'
 *     }
 *   }
 * }
 * ```
 *
 * You can chain multiple methods together to build up a more complex query.
 *
 * ```
 * bodybuilder()
 *   .query('match', 'message', 'this is a test')
 *   .filter('term', 'user', 'kimchy')
 *   .notFilter('term', 'user', 'cassie')
 *   .aggregation('terms', 'user')
 *   .build()
 * ```
 *
 * For nested sub-queries or sub-aggregations, pass a function as the last
 * argument and build the nested clause in the body of that function. Note that
 * you must `return` the builder object in the nested function. For example:
 *
 * ```
 * bodybuilder()
 *   .query('nested', 'path', 'obj1', (q) => {
 *     return q.query('match', 'obj1.color', 'blue')
 *   })
 *   .build()
 * ```
 *
 *
 *
 * The entire elasticsearch query DSL is available using the bodybuilder api.
 * There are many more examples in the docs as well as in the tests.
 *
 * @param  {Object} newBody Body to initialise with
 * @param  {Object} newQueries Queries to initialise with
 * @param  {Object} newFilters Filters to initialise with
 * @param  {Object} newAggregations Aggregations to initialise with
 * @return {bodybuilder} Builder.
 */
function bodybuilder(newBody, newQueries, newFilters, newAggregations) {
  const body = newBody || {};

  return Object.assign(
    {
      /**
       * Set a sort direction on a given field.
       *
       * ```
       * bodybuilder()
       *   .sort('timestamp', 'desc')
       *   .build()
       * ```
       * You can sort multiple fields at once
       *
       * ```
       * bodybuilder()
       *  .sort([
       *    {"categories": "desc"},
       *    {"content": "asc"}
       *  ])
       *   .build()
       * ```
       * Geo Distance sorting is also supported & it's the only sort type that allows for duplicates
       *
       * ```
       * bodyBuilder().sort([
       *     {
       *       _geo_distance: {
       *         'a.pin.location': [-70, 40],
       *         order: 'asc',
       *         unit: 'km',
       *         mode: 'min',
       *         distance_type: 'sloppy_arc'
       *       }
       *     },
       *     {
       *       _geo_distance: {
       *         'b.pin.location': [-140, 80],
       *         order: 'asc',
       *         unit: 'km',
       *         mode: 'min',
       *         distance_type: 'sloppy_arc'
       *       }
       *     }
       *   ])
       *   .sort([
       *     { timestamp: 'desc' },
       *     { content: 'desc' },
       *     { content: 'asc' },
       *    {"price" : {"order" : "asc", "mode" : "avg"}}
       *   ])
       * .build()
       * ```
       *
       * @param  {String} field             Field name.
       * @param  {String} [direction='asc'] A valid direction: 'asc' or 'desc'.
       * @returns {bodybuilder} Builder.
       */
      sort(field, direction = 'asc') {
        body.sort = body.sort || [];

        if (isArray(field)) {
          if (isPlainObject(body.sort)) {
            body.sort = [body.sort];
          }

          if (isArray(body.sort)) {
            each(field, (sorts) => {
              if (isString(sorts)) {
                return sortMerge(body.sort, sorts, direction);
              }
              each(sorts, (value, key) => {
                sortMerge(body.sort, key, value);
              });
            });
          }
        } else {
          sortMerge(body.sort, field, direction);
        }
        return this;
      },

      /**
       * Set a *from* offset value, for paginating a query.
       *
       * @param  {Number} quantity The offset from the first result you want to
       *                           fetch.
       * @returns {bodybuilder} Builder.
       */
      from(quantity) {
        body.from = quantity;
        return this;
      },

      /**
       * Set a *size* value for maximum results to return.
       *
       * @param  {Number} quantity Maximum number of results to return.
       * @returns {bodybuilder} Builder.
       */
      size(quantity) {
        body.size = quantity;
        return this;
      },

      /**
       * Set any key-value on the elasticsearch body.
       *
       * @param  {String} k Key.
       * @param  {any}    v Value.
       * @returns {bodybuilder} Builder.
       */
      rawOption(k, v) {
        body[k] = v;
        return this;
      },

      /**
       * Collect all queries, filters, and aggregations and build the entire
       * elasticsearch query.
       *
       * @param  {string} [version] (optional) Pass `'v1'` to build for the
       *                            elasticsearch 1.x query dsl.
       *
       * @return {Object} Elasticsearch query body.
       */
      build(version) {
        const queries = this.getQuery();
        const filters = this.getFilter();
        const aggregations = this.getAggregations();

        if (version === 'v1') {
          return _buildV1(body, queries, filters, aggregations);
        }

        return _build(body, queries, filters, aggregations);
      },

      /**
       * Returns a cloned instance of bodybuilder
       *
       * ```
       * const bodyA = bodybuilder().size(3);
       * const bodyB = bodyA.clone().from(2); // Doesn't affect bodyA
       * // bodyA: { size: 3 }
       * // bodyB: { size: 3, from: 2 }
       * ```
       *
       * @return {bodybuilder} Newly cloned bodybuilder instance
       */
      clone() {
        const queries = this.getRawQuery();
        const filters = this.getRawFilter();
        const aggregations = this.getRawAggregations();

        return bodybuilder(...[body, queries, filters, aggregations].map((obj) => cloneDeep(obj)));
      },
    },
    queryBuilder(undefined, newQueries),
    filterBuilder(undefined, newFilters),
    aggregationBuilder(newAggregations),
  );
}

function _buildV1(body, queries, filters, aggregations) {
  const clonedBody = cloneDeep(body);

  if (!isEmpty(filters)) {
    set(clonedBody, 'query.filtered.filter', filters);

    if (!isEmpty(queries)) {
      set(clonedBody, 'query.filtered.query', queries);
    }
  } else if (!isEmpty(queries)) {
    set(clonedBody, 'query', queries);
  }

  if (!isEmpty(aggregations)) {
    set(clonedBody, 'aggregations', aggregations);
  }
  return clonedBody;
}

function _build(body, queries, filters, aggregations) {
  const clonedBody = cloneDeep(body);

  if (!isEmpty(filters)) {
    const filterBody = {};
    const queryBody = {};
    set(filterBody, 'query.bool.filter', filters);
    if (!isEmpty(queries.bool)) {
      set(queryBody, 'query.bool', queries.bool);
    } else if (!isEmpty(queries)) {
      set(queryBody, 'query.bool.must', queries);
    }
    merge(clonedBody, filterBody, queryBody);
  } else if (!isEmpty(queries)) {
    set(clonedBody, 'query', queries);
  }

  if (!isEmpty(aggregations)) {
    set(clonedBody, 'aggs', aggregations);
  }

  return clonedBody;
}

export default bodybuilder;
